<template>
  <div class="content-box" v-loading="contentLoading">
        <div class="left-box">
            <p class="left-title">流程名称</p>
            <div class="left-content">
                <el-tree ref="nodeTree" :data="treeData" :props="defaultProps" node-key="id" :default-expanded-keys="[0]" 
                    :allow-drop="allowDrop"
                    :allow-drag="allowDrag"
                    @node-click="handleNodeClick"
                    @node-drag-end="handleDragEnd"
                    draggable highlight-current>
                    <template #default="{ node, data }">
                        <div class="tree-row">
                            <div class="add-child-box" v-if="data.isEdit">
                                <div @click.stop="" style="flex: 1;margin-right: 10px">
                                    <el-input  maxlength="6" class="input" v-model="data.editLabel" placeholder="请输入子节点名称"></el-input>
                                </div>
                                <el-button size="small" @click.stop="data.isEdit = false">取消</el-button>
                                <el-button type="primary" @click.stop="treeNodeSave(data)" size="small">保存</el-button>
                            </div>
                            <span v-else class="label">{{node.label}}</span>

                            <i @click.stop="addChild(data)" v-if="data.isFirst" class="el-icon-circle-plus-outline icon"></i>
                            <div v-else>
                                <i @click.stop="editChild(data)" class="el-icon-edit-outline edit-icon"></i>
                                <i @click.stop="delChild(data)" class="el-icon-delete del-icon"></i>
                            </div>
                        </div>
                    </template>
                </el-tree>
            </div>
        </div>
        <div class="right-box">
            <div style="display: flex;align-items: center;height: 28px;justify-content: space-between">
                <p class="right-title">子节点内容</p>
                <div>
                    <el-button :loading="richTextSaveLoading" :disabled="!activeNode.id" @click="richTextSave" type="primary" >保存</el-button>
                </div>
            </div>
            <div class="right-content">
                <RichText v-model="activeNode.stepDetail" :toolbarOptions="toolbarOptions" :disabled="!activeNode.id" />
            </div>
        </div>
        <el-dialog
            :title="addChildInfo.title"
            :visible.sync="addChildInfo.addChildVisible"
            width="600px"
            :close-on-click-modal="false"
            :before-close="handleClose">
            <el-button :disabled="addChildInfo.list?.length >= 30" @click="showInputFn" type="primary" size="small">新增子节点</el-button>
            <div class="list-box">
                <div class="node-row">
                    <span class="node-name" style="color: #000">
                        子节点名称
                    </span>
                    <span class="node-action" style="color: #000">
                        操作
                    </span>
                </div>
                <div class="node-row hight-node-row" v-for="(node, index) in addChildInfo.list" :key="index">
                    <div class="add-child-box node-name" style="margin-top: 0" v-if="node.isEdit">
                        <el-input style="flex: 1" maxlength="6" class="input" v-model="node.editLabel" placeholder="请输入子节点名称"></el-input>
                        <el-button size="small" @click="node.isEdit = false">取消</el-button>
                        <el-button type="primary" @click="childNodeRowSave(node)" size="small">保存</el-button>
                    </div>
                    <span class="node-name" v-else>
                        {{node.stepName}}
                    </span>

                    <div class="node-action">
                        <el-button type="text" @click="editChildNodeOne(node, index)" size="small">编辑</el-button>
                        <span class="line"></span>
                        <el-button type="text" @click="delChildNodeOne(node, index)" size="small">删除</el-button>
                    </div>
                </div>
                <div class="add-child-box add-child-box-tag" v-if="addChildInfo.showInput">
                    <el-input class="input" maxlength="6" v-model="addChildInfo.childNodeStr" placeholder="请输入子节点名称"></el-input>
                    <el-button size="small" @click="childNodeCancel">取消</el-button>
                    <el-button type="primary" @click="childNodeSave" size="small">保存</el-button>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button :loading="formSaveLoading" type="primary" @click="save">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import { Watch, Mixins, Component, Vue } from "vue-property-decorator";
import RichText from "@/components/rich-text.vue";

import { getStoreBuildTemplateApi, batchStoreBuildTemplateApi, deleteStoreBuildTemplateApi, batchStoreBuildTemplateSeqApi } from "@/api/buildStore";

import { cloneDeep } from "lodash";

import { getKey } from "@/utils/uuid";

@Component({
    components: {
        RichText
    }
})
export default class BuildStores extends Vue {
    toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      [{'color': []}], // dropdown with defaults from theme
      [{'align': []}],
      [{'clean': '清除'}], // remove formatting button
      ['image']
    ]
    treeData = [
        {
            stepName: "建店流程", id: 0, isFirst: true, 
            children: [
            ]
        }
    ]
    defaultProps = {
        children: 'children',
        label: 'stepName',
    }

    activeNode = {};

    childContent = "";

    addChildInfo = {
        addChildVisible: false,
        title: "",
        list: [],
        childNodeStr: "", // 新增子节点输入框
        showInput: false,
        editIndex: null,
    }

    contentLoading = false;

    formSaveLoading = false;

    richTextSaveLoading = false;

    checkedKeys = null;

    mounted() {
        this.initData();
    }

    initData() {
        this.getStoreBuildTemplate();
    }

    // 获取建店流程模板
    getStoreBuildTemplate() {
        this.contentLoading = true;
        getStoreBuildTemplateApi().then((res) => {
            const list = res.data?.map((node) => {
                return { ...node, _id: node.id }
            })
            this.treeData[0].children = list;

            const _node = list.find((n) => n.id == this.activeNode?.id);
            if (_node) {
                this.activeNode = _node;
            } else {
                this.activeNode = list[0] || {}; // 默认第一个子节点
            }
            this.setCheckedKey(this.activeNode.id)
        }).finally(() => {
            this.contentLoading = false;
        })
    }

    handleNodeClick(data) {
        this.activeNode = cloneDeep(data);
        this.setCheckedKey(data.id);
    }

    setCheckedKey(id) {
        this.$nextTick(() => {
            this.$refs.nodeTree.setCurrentKey(id)
        })
    }

    async richTextSave() {
        if(!this.activeNode.stepDetail) {
            this.$message.warning("子节点内容不能为空");
            return;
        }
        this.richTextSaveLoading = true;
        batchStoreBuildTemplateSeqApi([this.activeNode]).then(() => {
            this.$message.success("保存成功");
            this.initData();
            
        }).finally(() => {
            this.richTextSaveLoading = false;
        })
    }

    allowDrop(draggingNode, dropNode, type) {
        if (dropNode.data.isFirst || type == "inner") return false;
        return true;
    }
    allowDrag(draggingNode) {
        if (draggingNode.data.isFirst) return false;
        return true;
    }

    handleDragEnd(draggingNode, dropNode, dropType, ev){
        const list = this.treeData[0].children.map((el, index) => {
            return { ...el, stepSeq: index + 1 }
        })
        this.saveBuildStoreStepList(list)
    }

    save() {
        const list = this.addChildInfo.list?.map((el, index) => {
            const { id,  stepName, stepDetail = "" } = el;
            return { id,  stepName, stepDetail, stepSeq: index + 1 };
        })
        this.saveBuildStoreStepList(list)
    }

    async saveBuildStoreStepList(list) {
        this.formSaveLoading = true;
        await batchStoreBuildTemplateApi(list).then((res) => {
            this.cancel();
            this.initData();
        }).finally(() => {
            this.formSaveLoading = false;
        })
    }

    cancel() {
        Object.assign(this.addChildInfo, { addChildVisible: false, title: "", list: [] })
    }
    
    addChild(row) {
        this.formSaveLoading = false;
        Object.assign(this.addChildInfo, { addChildVisible: true, title: `${row.stepName}`, list: cloneDeep(row.children) || [] })
        this.addChildInfo.list.forEach(el => {
            el.isEdit = false;
        })
        this.addChildInfo = cloneDeep(this.addChildInfo)
    }

    showInputFn() {
        this.addChildInfo.list.forEach((node) => {
            node.isEdit = false;
        })
        this.addChildInfo.showInput = true;
        this.addChildInfo.childNodeStr = "";
        this.$nextTick(() => {
            const dom = document.querySelector(".add-child-box-tag");
            if (dom) {
                dom.scrollIntoView({behavior: 'smooth'})
            }
            
        })
    }

    editChildNodeOne(node, index){
        this.addChildInfo.list?.forEach((node) => {
            node.isEdit = false;
        })
        node.isEdit = true;
        node.editLabel = node.stepName;
        this.addChildInfo = cloneDeep(this.addChildInfo)
    }

    delChildNodeOne(node, index){
        this.addChildInfo.list?.splice(index, 1);
    }

    childNodeCancel() {
        this.addChildInfo.showInput = false;
        this.addChildInfo.childNodeStr = "";
        this.addChildInfo.editIndex = null;
    }

    childNodeSave() {
        if (!this.checkNodeStr(this.addChildInfo.childNodeStr, this.addChildInfo.list, {_id: getKey()})) {
            return;
        }
        this.addChildInfo.list.push({ stepName: this.addChildInfo.childNodeStr, isEdit: false, editLabel: "", content: "", _id: getKey() })
        this.childNodeCancel();
    }

    handleClose() {
        this.cancel();
    }

    editChild(row) {
        this.treeData[0].children.forEach((node) => {
            node.isEdit = false;
        })
        row.isEdit = true;
        row.editLabel = row.stepName;
        this.treeData = cloneDeep(this.treeData)
    }

    treeNodeSave(row) {
        const list = this.treeData[0]?.children;
        if (!this.checkNodeStr(row.editLabel, list, row)) {
            return;
        }
        this.childNodeRowSave(row, true)
    }

    childNodeRowSave(row, callApi) {
        if (!this.checkNodeStr(row.editLabel, this.addChildInfo.list, row)) {
            return;
        }
        row.isEdit = false;
        row.stepName = row.editLabel;
        const list = [row]
        if (callApi) {
            batchStoreBuildTemplateSeqApi(list).then(() => {
                this.initData();
            })
        }
    }

    delChild(row) {
        this.$confirm(`是否删除节点【${row.stepName}】`, "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((result) => {
            console.log("confirm del", row)
            deleteStoreBuildTemplateApi(row.id).then(() => {
                this.initData();
            })
        }).catch((err) => {
            console.log("cancel del")
        });
    }

    checkNodeStr(str, list, row) {
        if (!str?.trim()) {
            this.$message.warning("请输入正确节点名称");
            return false;
        }
        // 先判断一下重复
        const _node = list.find((el) => el.stepName == str);
        if (!_node) return true;
        if (row && row._id != _node._id) {
            this.$message.warning("节点名称重复")
            return false;
        }
        return true;
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/app";

.content-box {
  background: #fff;
  display: flex;
  padding: 20px;
}
.left-title, .right-title {
    font-size: 20px;
}
.left-box {
    width: 450px;
    margin-right: 20px;
    .left-content{
        height: calc(100% - 50px);
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 22px;
        padding: 20px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        .tree-row{
            width: 100%;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            .add-child-box{
                margin-top: 0;
                flex: 1;
                margin-right: 20px
            }
            .label{
                flex: 1;
            }
            .icon{
                position: relative;
                z-index: 3;
                color: #409EFF;
                font-size: 20px
            }
            .edit-icon{
                font-size: 18px;
            }
            .del-icon{
                font-size: 18px;
                color: #ff0000;
                margin-left: 10px;
            }
        }
    }
}
.right-box {
    flex: 1;
    .right-content{
        height: calc(100% - 50px);
        margin-top: 22px;

        :deep(.RichTextEditor-Wrap){
            height: 100%
        }
    }
}

.list-box{
    border: 1px solid #efefef;
    padding: 10px;
    margin-top: 20px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    .node-row {
        display: flex;
        align-items: center;
        min-height: 28px;
        .node-name{
            flex: 1;
        }
        .node-action{
            display: flex;
            color: #409EFF;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 0;
            .line{
                width: 1px;
                height: 10px;
                margin: 0 8px;
                background-color: #409EFF;
            }
        }
    }
}
.add-child-box{
    display: flex;
    align-items: center;
    margin-top: 10px;
    .input {
        margin-right: 10px;
        :deep(.el-input__inner) {
            height: 34px;
        }
    }
}
.hight-node-row{
    &:hover {
        cursor: pointer;
        background-color: #efefef;
    }
}

</style>
<style lang="scss">
.el-tree-node__content{
    height: auto;
    display: flex;
    align-items: center;
}
.el-tree-node.is-current > .el-tree-node__content {
//  background-color: deepskyblue !important;
}
.right-content{
    .ql-container{
        flex: 1;
    }
    .ql-editor {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .ql-toolbar {
        line-height: normal;
    }
    .resultSug{
        padding-left: 5px;
        padding-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 25px;
    }
    .yy-video{
        width: 100%;
    }
}

</style>